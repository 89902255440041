import './index.css';

import React, {useState, useEffect} from 'react';
import {Link, Redirect} from "react-router-dom";
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { updateUserInformation } from '../../redux/actions';
import classNames from 'classnames';
import IconTextWithFa from '../Commons/iconTextWithFa';
import IconTextWithImg from '../Commons/iconTextWithImg';
import phoneIcon from './images/phone.png';
import logo from './images/ufuk-arac-takip-sistemleri-logo.png';
import madeInGermany from './images/made_in_germany.png';
import flagTr from './images/tr.png';
import flagDe from './images/de.png';
import flagEn from './images/en.png';
import flagNl from './images/nl.png';

import { Navbar, Nav, NavDropdown, Modal, Container } from "react-bootstrap";

import UserInformation from '../../services/UserInformation';
import MobileOnline from '../../services/MobileOnline';
import SupportService from '../../services/SupportService';

const langIcons = [flagDe, flagEn, flagTr, flagNl];

function HeaderNav(props) {

    const userInformation = new UserInformation();
    const mobileOnline = new MobileOnline();
    const supportService = new SupportService();
    let selectedHour = "";
    let inputName;
    let inputCompany;
    let inputPhone;
    let inputEmail;
    let inputMessage;

    const activePage = props.path.slice(1);
    const [showLoginDevices, setShowLoginDevices] = useState(false);
    const [showSupportForm, setSupportForm] = useState(false);
    const [userData, setUserData] = useState([]);
    const [redirect, setRedirect] = useState('');
    const [loginDevices, setLoginDevices] = useState({loginLogs: [], lastLoginDevices: []});
    
    const handleLanguage = (langId) => {
        userInformation.setLanguage(langId).then(() => {
            props.updateUserInformation({user: {...props.user, language: langId}});
        });
    };

    const handleTimeZone = (timezone) => {
        userInformation.setTimezone(timezone).then(() => {
            props.updateUserInformation({user: {...props.user, time_zone: timezone}});
            window.location.reload();
        });
    };

    const handleLogout = () => {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("horizont:token");
        window.location.href = "https://www.gps-takip-sistemi.com/#/login";
    };

    const fetchUserInformation = async () => {
        let response = await userInformation.getUserInformation();
        props.updateUserInformation({user: response.data});
    };
    
    const fetchLoginDevices = async () => {
        let responseLogs = await userInformation.getLoginLogs();
        let responseMobile = await mobileOnline.getLoginDevices(props.user.user_id);
        setLoginDevices({...loginDevices, loginLogs: responseLogs.data, lastLoginDevices: responseMobile.data });
    };

    const handleHourChange = (event) => {
        selectedHour = event.target.value;
    };

    const sendTechnicalReport_ = async () => {
        await supportService.sendTechnicalReport(inputName.value, inputCompany.value, inputPhone.value, inputEmail.value, inputMessage.value, selectedHour);
    };

    useEffect(() => {
        if (!props.user.hasOwnProperty('user_id')) {
            try {
                fetchUserInformation();
                let _userData = sessionStorage.getItem("user");
                if (_userData) {
                    setUserData(_userData);
                }
    
            } catch(err) { }
        }
    });

    const redirectTo = () => {
        if (redirect)
            return <Redirect to={redirect} />;
    };

    return (
        <React.Fragment>
            {redirectTo()}
            <div style={{fontSize: 12}} className='alert alert-warning m-0'><b>Değerli Araç Kiralama Firmaları</b>, Yasal düzenleme gereği, araç verilerinizin 6 aylık periyotlarla kaydedilmesi zorunludur. Bu yükümlülüğün yerine getirilmemesi durumunda firmamız sorumluluk kabul etmeyecektir.</div>
            <Navbar fluid="true" collapseOnSelect className="bg-horizont" expand="lg">
                <Nav className="flex-row">
                    <Link to="/" className="nav-link no-border"><i className="fa fa-signal"></i></Link>
                    {/* <Link to="/" className="nav-link no-border"><i className="fa fa-star"></i></Link>
                    <Link to="/" className="nav-link no-border car-icon"><img alt="" src={carIcon} /></Link> */}
                </Nav>
                <Navbar.Toggle onClick={props.onClick} aria-controls="header-nav-menu" />
                <Navbar.Collapse open={props.isOpen} id="header-nav-menu">
                    <Nav className="mr-auto">
                        <Link className={classNames('nav-link', {'active': (activePage === "")})} to={props.listType === 'big-list' ? "/big-list" : "/" }>
                            <FormattedMessage id="MENU.ALL_DEVICES"/>
                        </Link>
                        <Link className={classNames('nav-link', {'active': (activePage === "routes")})} to="/routes">
                            <FormattedMessage id="MENU.ROUTES" />
                        </Link>
                        <Link className={classNames('nav-link', {'active': (activePage === "electronic-reports")})} to="/electronic-reports">
                            <FormattedMessage id="MENU.REPORTS" />
                        </Link>
                        <NavDropdown className="dropdown-menu-navbar" title={<FormattedMessage id="MENU.FUNCTIONS" />}>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "theft-protection")})} to="/theft-protection"><FormattedMessage id="MENU.THEFT"/></Link>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "theft-protection-2")})} to="/theft-protection-2"><FormattedMessage id="USER_RIGHTS.diebstahlschutz_indi"/></Link>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "locations")})} to="/locations"><FormattedMessage id="MENU.MYLOCATIONS"/></Link>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "vehicle-service")})} to="/vehicle-service"><FormattedMessage id="MENU.SERVICE"/></Link>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "rent-a-car")})} to="/rent-a-car"><FormattedMessage id="MENU.RENT"/></Link>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "user-rights")})} to="/user-rights"><FormattedMessage id="MENU.USER_RIGHTS"/></Link>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "vehicle-groups")})} to="/vehicle-groups"><FormattedMessage id="MENU.GROUPS"/></Link>
                            <a target="_blank" className="dropdown-item"
                                href={`https://www.gps-takip-sistemi.com/horizont-route-tracking/#/login?auth=${userData}`}>
                                <i className="fa fa-star"></i> <FormattedMessage id="MENU.ROUTE_TRACKING"/>
                            </a>
                            <Link className={classNames('dropdown-item', {'active': (activePage === "tank-signals")})} to="/tank-signals">Tank Sinyalleri</Link>
                            {/* <Link className={classNames('dropdown-item', {'active': (activePage === "customer-groups")})} to="/customer-groups"><FormattedMessage id="MENU.CUSTOMER_GROUPS"/></Link>*/}
                            <Link className={classNames('dropdown-item', {'active': (activePage === "web-site-integration")})} to="/web-site-integration"><FormattedMessage id="MENU.WEB_INTEGRATION"/></Link> 
                        </NavDropdown>
                        { (props.user.user_group_id === 2) ? <Link className={'nav-link bg-danger'} to="/data-delete"><FormattedMessage id="MENU.DATA_DELETION" /></Link> : null }
                        <Link onClick={() => setSupportForm(true)} className={classNames('nav-link', {'active': (activePage === "support")})} to="#">
                            <FormattedMessage id="MENU.TECHNICAL_SUPPORT" />
                        </Link>
                        <div className="logo">
                            <a href="http://www.ufuk-aractakip.com.tr/" target="_blank" rel="noopener noreferrer"><img className="nav-logo d-none d-lg-block" src={logo} alt="" /></a>
                        </div>
                        <img className="nav-logo d-none d-lg-block" src={madeInGermany} alt="" />
                        <div style={{ backgroundImage: `url(${phoneIcon})`, backgroundRepeat: 'no-repeat' }} className="phone d-none d-lg-block">
                            <div className="phone-title"><FormattedMessage id="GENERAL.PHONE_NUMBER_TEXT" /></div>
                            <div className="phone-number">0232 339 60 06</div>
                        </div>
                    </Nav>
                    <Nav>
                        <NavDropdown className="dropdown-menu-navbar no-border right" title={<IconTextWithFa icon="user" text={props.user.user_name} />}>
                            <NavDropdown className="dropdown-submenu left" title={<IconTextWithImg icon={langIcons[props.user.language]} text="Dil Seçimi" />}>
                                <Nav.Link onClick={() => handleLanguage(0)} className={classNames('dropdown-item', {'active': (props.user.language === 0)})}>
                                    <IconTextWithImg icon={flagDe} text="Almanca" />
                                </Nav.Link>
                                <Nav.Link onClick={() => handleLanguage(1)} className={classNames('dropdown-item', {'active': (props.user.language === 1)})}>
                                    <IconTextWithImg icon={flagEn} text="İngilizce" />
                                </Nav.Link>
                                <Nav.Link onClick={() => handleLanguage(2)} className={classNames('dropdown-item', {'active': (props.user.language === 2)})}>
                                    <IconTextWithImg icon={flagTr} text="Türkçe" />
                                </Nav.Link>
                                <Nav.Link onClick={() => handleLanguage(3)} className={classNames('dropdown-item', {'active': (props.user.language === 3)})}>
                                    <IconTextWithImg icon={flagNl} text="Hollandaca" />
                                </Nav.Link>
                            </NavDropdown>
                            <NavDropdown className="dropdown-submenu left" title={<IconTextWithFa icon="clock" text="Zaman Dilimi" />}>
                                <Nav.Link onClick={() => handleTimeZone('Africa/Cairo')} 
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Africa/Cairo')})}>Africa/Cairo</Nav.Link>

                                <Nav.Link onClick={() => handleTimeZone('Europe/Amsterdam')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Amsterdam')})}>Europe/Amsterdam</Nav.Link>

                                <Nav.Link onClick={() => handleTimeZone('Europe/Berlin')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Berlin')})}>Europe/Berlin</Nav.Link>

                                <Nav.Link onClick={() => handleTimeZone('Europe/Istanbul')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Istanbul')})}>Europe/Istanbul</Nav.Link>

                                <Nav.Link onClick={() => handleTimeZone('Europe/London')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/London')})}>Europe/London</Nav.Link>
                                
                                <Nav.Link onClick={() => handleTimeZone('Europe/Luxembourg')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Luxembourg')})}>Europe/Luxembourg</Nav.Link>
                                
                                <Nav.Link onClick={() => handleTimeZone('Europe/Madrid')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Madrid')})}>Europe/Madrid</Nav.Link>
                                
                                <Nav.Link onClick={() => handleTimeZone('Europe/Paris')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Paris')})}>Europe/Paris</Nav.Link>

                                <Nav.Link onClick={() => handleTimeZone('Europe/Rome')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Rome')})}>Europe/Rome</Nav.Link>
                                
                                <Nav.Link onClick={() => handleTimeZone('Europe/Vienna')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Vienna')})}>Europe/Vienna</Nav.Link>
                                
                                <Nav.Link onClick={() => handleTimeZone('Europe/Zurich')}
                                className={classNames('dropdown-item', {'active': (props.user.time_zone === 'Europe/Zurich')})}>Europe/Zurich</Nav.Link>
                            </NavDropdown>
                            <Nav.Link onClick={() => setShowLoginDevices(true)} className="dropdown-item"><IconTextWithFa icon="mobile" text="Oturum Açılan Cihazlar" /></Nav.Link>
                            <a href="https://www.gps-takip-sistemi.com/v1/templates/agb.html" target='_blank' class="dropdown-item nav-link" role="button"><span>Kullanım Sözleşmesi</span></a>
                            <Nav.Link onClick={handleLogout} className="dropdown-item"><IconTextWithFa icon="sign-out-alt" text="Oturum Kapat" /></Nav.Link>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Modal
                size="lg"
                show={showLoginDevices}
                aria-labelledby="login-devices"
                onHide={() => setShowLoginDevices(false)}
                onShow={fetchLoginDevices}
            >
                <Modal.Header closeButton>
                <Modal.Title id="login-devices">
                    <FormattedMessage id="MENU.LOGIN_DEVICES"/>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dropdown-menu-navbar">
                    <p style={{textAlign: "center"}}><b><FormattedMessage id="MENU.LATEST_LOGIN"/></b></p>
                    <Container>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="MENU.PLATFORM"/></th>
                                    <th><FormattedMessage id="MENU.BROWSER"/></th>
                                    <th><FormattedMessage id="MENU.BROWSER_VERSION"/></th>
                                    <th><FormattedMessage id="MENU.LOGIN_DATE"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loginDevices.loginLogs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item['platform']}</td>
                                            <td>{item['browser']}</td>
                                            <td>{item['browser_version']}</td>
                                            <td>{item['activity_date']}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Container>
                    <p style={{textAlign: "center"}}><b><FormattedMessage id="MENU.LATEST_LOGIN"/></b></p>
                    <Container>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="MENU.PLATFORM"/></th>
                                    <th><FormattedMessage id="MENU.PLATFORM_VERSION"/></th>
                                    <th><FormattedMessage id="MENU.APP_VERSION"/></th>
                                    <th><FormattedMessage id="MENU.LOGIN_DATE"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loginDevices.lastLoginDevices.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item['platform']}</td>
                                            <td>{item['version']}</td>
                                            <td>{item['app_version']}</td>
                                            <td>{item['created_date']}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showSupportForm}
                aria-labelledby="technical-support-mail"
                onHide={() => setSupportForm(false)}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="technical-support-mail">
                    <form name="form">
                        <h3><FormattedMessage id="TECHNICAL_SUPPORT.HEAD"/></h3>
                        <div>
                        <p><FormattedMessage id="TECHNICAL_SUPPORT.GENERAL"/></p>
                        <FormattedMessage id="TECHNICAL_SUPPORT.NAME">
                        {
                            placeholder => (
                                <p><input ref={n => inputName = n} type="text"  className="form-control"  name="sName" placeholder={placeholder} required/></p>
                            )
                        }
                        </FormattedMessage>
                        <FormattedMessage id="TECHNICAL_SUPPORT.FIRMA">
                        {
                            placeholder => (
                                <p><input ref={n => inputCompany = n} type="text"  className="form-control"  name="company" placeholder={placeholder}/></p>
                            )
                        }
                        </FormattedMessage>
                        <FormattedMessage id="TECHNICAL_SUPPORT.PHONE">
                        {
                            placeholder => (
                                <p><input ref={n => inputPhone = n} type="text"  className="form-control"  name="sPhone"  placeholder={placeholder}/></p>      
                            )
                        }
                        </FormattedMessage>
                        <FormattedMessage id="TECHNICAL_SUPPORT.EMAIL">
                        {
                            placeholder => (
                                <p><input ref={n => inputEmail = n} type="email" className="form-control"  name="sEmail" required placeholder={placeholder}/></p>
                            )
                        }
                        </FormattedMessage>
                        
                        <br></br>
                        
                        <p><span><FormattedMessage id="TECHNICAL_SUPPORT.MESSAGE"/></span> <br></br>
                        <FormattedMessage id="TECHNICAL_SUPPORT.INFO">
                        {
                            placeholder => (
                                <textarea ref={n => inputMessage = n} className="form-control" rows="5" placeholder={placeholder} name="sMessage" required></textarea>
                            )
                        }
                        </FormattedMessage>
                        </p>
                        <br></br>

                        <p>
                            <span><FormattedMessage id="TECHNICAL_SUPPORT.CALL_TIME"/></span>
                            <select className="form-control select-car input-sm" onChange={(event) => handleHourChange(event)}>
                                <FormattedMessage id="TECHNICAL_SUPPORT.NOW">
                                {
                                    value => (
                                    <option key={0} value="Hemen">{value}</option>
                                    )
                                }
                                </FormattedMessage>
                                <option key={1} value="08:00 - 10:00">08:00 - 10:00</option>
                                <option key={2} value="10:00 - 12:00">10:00 - 12:00</option>
                                <option key={3} value="12:00 - 14:00">12:00 - 14:00</option>
                                <option key={4} value="14:00 - 16:00">14:00 - 16:00</option>
                                <option key={5} value="16:00 - 18:00">16:00 - 18:00</option>
                            </select>
                        </p>

                        </div>
                        <div className="ngdialog-buttons">
                            <button type="submit" onClick={sendTechnicalReport_} className="ngdialog-button ngdialog-button-primary"><FormattedMessage id="TECHNICAL_SUPPORT.SEND"/></button>
                            <button type="button" onClick={() => setSupportForm(false)} className="ngdialog-button ngdialog-button-secondary"><FormattedMessage id="GENERAL.TURN_BACK"/></button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = function(state) {
    return {
        user: state.userInformationReducer.user,
        listType: state.deviceListTypeReducer.listType
    }
};

const mapDispatchToProps = {
    updateUserInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);